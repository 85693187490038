.brandImg {
  width: 150px;
}

.divider-line-header {
  width: 100%;
  background-color: #d8e6ec;
  margin: 15px 0px;
  // width: 95%;
  // margin: 5% 3%;
  // background-color: #d8e6ec;
}
