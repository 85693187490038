.mainContainer {
    position: absolute;
    top: 125px;
    width: 100%;
    padding: 0 12px;
    z-index: 1000;
}

.workspaceDropdown {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
    padding: 16px 0;
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #e0e0e0;
        border-radius: 3px;
    }
}

.dropdownHeader {
    padding: 0 16px 12px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 8px;

    > span {
        display: block;
        font-weight: 600;
        color: #1a1a1a;
        margin-bottom: 12px;
    }

    .searchWrapper {
        input {
            width: 100%;
            padding: 8px 12px;
            border: 1px solid #e0e0e0;
            border-radius: 6px;
            font-size: 14px;
            transition: all 0.2s;

            &:focus {
                outline: none;
                border-color: #40a9ff;
                box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
            }
        }
    }
}

.workspaceItem {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #f5f5f5;
    }
}

.workspaceIcon {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    margin-right: 12px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f0f0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    span {
        font-weight: 600;
        color: #666;
    }
}

.workspaceInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.workspaceName {
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 2px;
}

.workspaceMembers {
    font-size: 12px;
    color: #666;
}

.activeIndicator {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activeDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #52c41a;
}