.app-loading {
  color: #2570ff;
  font: 300 4em/150% Impact;
  text-align: center;
  display: inline-block;
  position: fixed;
  z-index: 999;
  width: 84px;
  height: 84px;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -42px;
  animation: rotating 2s linear infinite;
}
  
  /* loading dots */

  
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  