.App {
  text-align: center;
}

.progress-info-text {
  align-items: center;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  color: white;
  font-weight: bold;
}

.dot-animation{
  width: 8%;
  color: white;
  align-self: flex-end;
  margin-bottom: 25px;
}
.dot-animation:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026";
  width: 0px
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

.progress-sub-text{
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    line-height: 16.94px;
    color: white;
    text-align: center;
}

.ant-progress-text {
  color: white !important;
  margin-top: 25px;
  margin-left: 32%;
  font-weight: 600;
}

.soonTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  h2 {
    font-size: 24px;
    font-family: "SF_Bold", sans-serif;
    line-height: 30px;
  }
  .ant-empty-description {
    color: #9d9d9d;
    font-family: "SF_Medium", sans-serif;
    font-size: 16px;
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background: #cbcbcb;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #797979;
  transition: 1s ease-in-out;
}

.pre-loader {
  background: #ffffff8c;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
  img {
    width: 80px;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.navbar-brand {
  display: none;
}
.search {
  --background: #ffffff;
  --text-color: #8492A6;
  --primary-color: #F8FAFF;
  --border-radius: 6px;
  --width: 190px;
  --height: 32px;
  background: var(--background);
  width: auto;
  height: var(--height);
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  box-shadow: 0 10px 30px rgba(#414856, .05);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  input[type="text"] {
    position: relative;
    width: var(--height);
    height: var(--height);
    font: 400 16px 'Varela Round', sans-serif;
    color: var(--text-color);
    border: 0;
    box-sizing: border-box;
    outline: none;
    padding: 0 0 0 32px;
    transition: width .6s ease;
    z-index: 8;
    opacity: 0;
    cursor: pointer;
    &:focus {
      z-index: 0;
      opacity: 1;
      width: var(--width);
      ~ .symbol {
        &::before {
          width: 0%;
        }
        &:after {
          clip-path: inset(0% 0% 0% 100%);
          transition: clip-path .04s linear .105s;
        }
        .cloud {
          top: -30px;
          left: -30px;
          transform: translate(0, 0);
          transition: all .6s ease;
        }
        .lens {
          top: 9px;
          left: 10px;
          transform: translate(0, 0);
          fill: var(--primary-color);
          transition: top .5s ease .1s, left .5s ease .1s, fill .3s ease;
        }
      }
    }
  }
  .symbol {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      content:"";
      position: absolute;
      right: 0;
      width: 100%;
      height: 100%;
      background: var(--primary-color);
      z-index: -1;
      transition: width .6s ease;
    }
    &:after {
      content:"";
      position: absolute;
      top: 21px;
      left: 21px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: var(--primary-color);
      z-index: 1;
      clip-path: inset(0% 0% 0% 0%);
      transition: clip-path .04s linear .225s;
    }
    .cloud,
    .lens {
      position: absolute;
      fill: #fff;
      stroke: none;
      top: 50%;
      left: 50%;
    }
    .cloud {
      width: 35px;
      height: 32px;
      transform: translate(-50%, -60%);
      transition: all .6s ease;
    }
    .lens {
      fill: #fff;
      width: 16px;
      height: 16px;
      z-index: 2;
      top: 9px;
      left: 9px;
      transition: top .3s ease, left .3s ease, fill .2s ease .2s;
    }
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ant-modal {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 14px;
  padding-bottom: 0;
  button {
    width: fit-content;
  }
}
.ant-modal-header {
  padding: 35px 24px 24px 24px;
  color: #1f2d3d;
  background: #fff;
  border-bottom: 0;
  border-radius: 14px 14px 0 0;
  .ant-modal-title {
    color: #8492A6;
    font-size: 18px;
    line-height: 26px;
    font-family: "SF_Medium", sans-serif;
  }
}
.ant-modal-content {
  box-shadow: none;
  border-radius: 14px 14px;
}
.ant-modal-close-x {
  display: block;
  width: 40px;
  height: 35px;
  font-size: 16px;
  font-style: normal;
  text-align: center;
  text-transform: none;
  &:focus-within, &:focus, &:focus-visible {
    border: none;
    outline: none;
  }
  .anticon svg {
    display: inline-block;
    color: #DAE6FF;
  }
}
.ant-modal-body {
  padding-top: 0;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}

.ant-input-label {
  font-size: 14px;
  line-height: 16px;
  color: #8492A6;
  opacity: 1;
  font-family: 'SF_medium', sans-serif;
  margin-bottom: 10px;
}
.ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 10px;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 9px 10px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 20px;
  font-family: 'SF_Regular', sans-serif;
  background: #fafbfc 0% 0% no-repeat padding-box;
  border: 1px solid #CDD3DC;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.video-player-modal {
  background: #fafafa !important;
  padding: 1rem;  
  max-width: 1000px;
  width:100%;

.ant-modal-close-x {
    background: #49505b;
    position: fixed;
    border-radius: 100%;
    width: 64px;
    height: 64px;

    &:hover {
      background: #272b31;
    }

    > div {
      width: 64px;
      height: 64px;
      margin: 0;

      > span {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
      }
    }
  }

  .ant-modal-content {
    background: transparent !important;
  }

  .ant-modal-body {
    padding: 0;
    border-radius: 6px;
    overflow: hidden;
  }

  background: black;
  .video-player {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    video {
      width: auto;
      height: auto;
      max-height: 80vh;
      max-width: 80vw;
      // min-width: 1280px;
      // min-height: 768px;
      transition: all 150ms;
      border-radius: 6px;
      overflow: hidden;
      animation: video-fade-in 400ms linear 0s 1;

      @keyframes video-fade-in {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      
      
        
    }
  }
}

.recordUploadModal {
  .ant-modal-close {
    right: 30px;
    top: 10px;
  }
  .ant-modal-close-x {
    div {
      display: flex;
      align-items: center;
      .customClose {
        margin-right: 2px;
        font-size: 14px;
        svg{
          color: #737373;
        }
      }
      span.closeText {
        font-size: 14px;
        color: #B4B4B4;
        font-family: "SF_Regular", sans-serif;
        font-weight: 400;
      }
    }
  }
  .modalImgTitle {
    display: flex;
    align-items: center;
    padding: 30px 0;
    img {
      max-width: 120px;
      width: 100%;
      margin-right: 15px;
    }
    h2 {
      color: #8492A6;
      font-size: 18px;
      line-height: 19px;
      font-family: "SF_Regular", sans-serif;
      margin-bottom: 0;
    }
  }
  .modalUpload-dashboard-skinning {
    position: relative;
    height: 135px;
    .modalDragger-container {
      border: 1px dashed #ffffff;
      border-radius: 6px;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      // z-index: 999;
      .ant-upload.ant-upload-drag {
        border: none;
        border-radius: 10px;
        top: 0;
        left: 0;
        .ant-upload {
          padding: 0;
        }
      }
      .ant-upload-list {

        .ant-upload-list-text-container {
          height: 135px;
          position: absolute;
          width: 100%;
          padding: 8px;
          top: 0;
          left: 0;
          z-index: 999;
          background: white;
          .ant-upload-list-item {

            &:hover .ant-upload-list-item-info {
              background: white;
            }
            
            .ant-upload-list-item-progress {
              top: 20px;
            }
          }
        }
      }
      .modalDrag-dashboard {
        //background: url(" + "https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350" + ");
        background-size: cover;
        background-repeat: no-repeat;
        height: 135px;
        border-radius: 6px;

        
      }
    }
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#sidebar {
  padding-left: 0;
}
.min-logo {
  display: none;
}
.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 8px;
  // padding: 0 8px;
  margin-top: 10px;
  height: 46px;
  background: white;
  border-radius: 6px;
  border:1px solid #F2F2F2;
}

.collapseDot.forMob {
  display: none;
}
.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-title-content{
  font-family: "SF_Regular", sans-serif;
  .workSpaceMenuBtn {
    position: relative;
    margin-top: -40px;
    margin-left: 30px;
    font-family: "SF_Regular", sans-serif;
  }
  .createWorkspace {

  }
}
.ant-layout-sider {
  z-index: 9;
  background: #fff;

  .ant-menu {
    background: transparent;
    border: none;
  }
  .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 0px);
    img {
      width: 20px;
    }
  }
  .collapseDot {
    padding: 10px 2px;
    background: #fff;
    color: #BDC8D7;
    border:1px solid #f2f2f2;
    opacity: 0;
    transition: all .4s ease-in-out;
    &:hover{
      border:1px solid #0080ff;

    }
  }
  &:hover {
    .collapseDot {
      opacity: 99;
    }
  }
  &.ant-layout-sider-collapsed {
    .collapseDot {
      position: absolute;
      transition: all .4s ease-in-out;
      left: 7px;
      top: 21px;
      right: 7px;
      padding: 10px 10px;
      opacity: 0;
      border-radius: 4px;
      font-size: 26px;
    }
    &:hover {
      .collapseDot {
        position: absolute;
        transition: all .4s ease-in-out;
        left: 7px;
        top: 21px;
        right: 7px;
        padding: 10px 10px;
        opacity: 99;
        border-radius: 4px;
        font-size: 26px;
      }
      .min-logo {
        opacity: 0;
        transition: all .4s ease-out;
      }
    }
  }


  .firstMenuWrap {
    width: 99%;
    margin: 0 auto;
    &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, &.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      left: 0;
      padding: 0 calc(50% - 28px / 2) !important;
      text-overflow: clip;
    }
    .ant-menu-submenu-title {
      background-color: #FBFDFF;
      border-radius: 5px;
      height: 46px;
      padding-left: 16px !important;
    }
    &.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      height: 46px;
      img {
        min-width: 27px;
      }
    }
    .firstMenu.ant-menu-submenu-selected {
      .ant-menu-submenu-title, .ant-menu-item {
        border: 1px solid #0080ff;
        svg{
          path{
            fill:#0080ff;
          }
        }
        .anticon {
          color: #0080ff !important;
        }
      }
    }
    .firstMenu {
      margin: 22px 8px 0 8px;
      ul{
        display: none;
      }
      .ant-menu-submenu-arrow{
        display: none;
      }
      .ant-menu-submenu-title, .ant-menu-item {
        border: 1px solid #F2F2F2;
        margin-bottom: 0;
        margin-top: 0;
        &:hover {
          border: 1px solid #0080ff;
          svg{
            path{
              fill:#0080ff;
            }
          }
          .anticon {
            color: #0080ff !important;
          }
        }
      }
   
      .ant-menu-title-content {
        font-family: "SF_semiBold", sans-serif;
        .workSpaceMenuBtn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            color: #000000;
            font-family: "SF_Bold", sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
          }
          label {
            color: #607CAC;
            font-family: "SF_Regular", sans-serif;
          }
          label, p {
            // line-height: 18px;
            margin: 0;
          }
        }
      }
      .ant-menu.ant-menu-sub  {
        margin: 0px 0 15px 0;
        .subItem {
          background-color: #fff;
          height: 65px;
          padding-left: 16px !important;
          .createWorkspace {
            color: #0080ff;
            text-align: center;
          }
        }
      }
    }
  }

  .SimpleMenuWrap {
    font-family: "SF_semiBold", sans-serif;
    margin: 25px 0 10px 0;
    .ant-menu-item {
      margin-bottom: 0;
      height: 31px;
      .ant-menu-title-content {
        color: #607CAC ;
        transition: 0.4s ease-in-out;
      }
      &:hover {
        .ant-menu-title-content {
          color: #1890ff;
          transition: 0.4s ease-in-out;
        }
      }
    }
  }
  //SECOND MENU

  .secondMenu {
    background: none;
    margin-bottom: 26px;
    .ant-menu-submenu-title, .ant-menu-item {
      border: 1px solid transparent;
    }
    li.ant-menu-item {
      font-size: 13px;
      font-family: "SF_Bold", sans-serif;
      height: 31px;
      margin-top: 0;
      color: #607CAC;
      margin-bottom: 0 !important;
      .ant-menu-title-content {
        color: #607CAC ;
      }
      img {
        width: 20px;
      }
      svg {
        width: 20px;
        stroke: #8492A6;
      }
      &:hover {
        .ant-menu-title-content {
          color: #1890ff;
        }
      }
    }
    &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item, &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, &.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 0 calc(50% - 22px / 2);
    }
    &.ant-menu-inline-collapsed {
      li.ant-menu-item {
        background: #fff;
        img {
          margin-top: 5px;
        }
      }

    }
    &.ant-menu-inline {
      //width: 94%;
      //border: none;
    }
    .ant-menu-item {
      height: 30px;
    }
    .ant-menu-item-selected {
      border: none !important;
      background-color: transparent !important;
    }
    .ant-menu-item-selected::after {
      border: none !important;
      background-color: transparent !important;
    }
    .ant-menu-title-content {
      color: #172B4D;
    }
  }


  //THIRD MENU

  .thirdMenu {
    margin: 0px 8px 0 8px;
    font-family: "SF_Regular", sans-serif;
    font-size: 13px;
    background-color: #FBFDFF;
    border-radius: 6px;
    //padding: 15px 0;
    padding: 15px 0 15px 0;
    .AppsLink {
      img.appIcon {
        border-radius: 0;
      }
    }
    &.ant-menu-inline {
      width: 94%;
      border: none;
    }

    li.ant-menu-item {
      padding-left: 16px !important;
      height: 40px;
      font-size: 18px;
      font-family: "SF_Bold", sans-serif;
      color: #000;
      line-height: 26px;
      border-radius: 6px;
      margin: 0 0 12px;
      img {
        border-radius: 4px;
      }
      &:first-child {
        font-size: 13px;
        font-family: "SF_Bold", sans-serif;
        color: #607CAC;
      }
      &:last-child {
        margin: 0;
      }
    }
    &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item, &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, &.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 0 calc(50% - 36px / 2);
      height: auto;
    }
    &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item.AppsLink {
      padding: 0 calc(50% - 32px / 2);
      padding-left: 16px !important;
      //margin-bottom: 19px;
    }
    &.ant-menu.ant-menu-inline-collapsed {
      li.ant-menu-item {
        padding-left: 8px !important;
      }
    }
    .ant-menu-selected::after,  .ant-menu-item-selected::after {
      display: none;
    }
    &.ant-menu.ant-menu-dark, &.ant-menu-dark .ant-menu-sub, &.ant-menu.ant-menu-dark .ant-menu-sub {
      color: #1d2124;
      background: #F3F6F9;
    }
    &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #fff;
    }

    //Forth Menu (Add Button)
    &.addToolMenu {
      margin: 1px 8px 0 8px;
      padding: 5px 0 0 0;
      border-radius: 0 0 6px 6px;
      .addApp {
        text-align: center;
        margin-bottom: 0;
        span {
          font-size: 24px;
          transition: 0.5s ease-in-out;
          color: #DAE6FF;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
  &.ant-layout-sider-collapsed {
    .firstMenuWrap {
      .ant-menu-submenu-title {
        background: #fff;
        svg {
          margin-top: 8px !important;
          margin-left: 1px !important;
        }
      }
    }
    .thirdMenu {
      background: #fff;
      padding: 15px 0 8px 0;
      img.appIcon {
        max-width: 25px !important;
        border-radius: 0;
      }
      &.addToolMenu {
        margin-top: 0;

        &:hover {
          .addApp {
            span {
              svg {
                stroke: #0080ff;
                fill: #0080ff;
              }
            }
          }

        }
      }
    }
    .collapseControl {
      .anticon-menu-unfold {
        position: absolute;
        left: 18px;
        font-size: 24px;
        top: 20px;
        visibility: hidden;
      }
      &:hover {
        .anticon-menu-unfold {
          visibility: visible;
        }
      }
      .min-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        padding: 0;
      }
      .max-logo {
        display: none;
      }
    }
    .SimpleMenuWrap {
      display: none;
    }
    &.ant-layout-sider .secondMenu {
      background: #fff;
      border-radius: 6px;
      margin: 18px 8px 12px 8px;
      li.ant-menu-item {
        height: 50px;
        margin-bottom: 0;
        border-radius: 6px;
        img, svg {
          margin-top: 14px;
        }
        &:first-child {
          border-radius: 6px 6px 0 0!important;
        }
        &:last-child {
          //border-radius: 0 0 6px 6px!important;
          border-radius: 6px!important;
        }
      }
    }
  }
}


.empty-dashboard {
  color: rgba(0, 0, 0, 0.38);
  position: relative;
  font-size: 1.2em;
  text-align: center;
  background: #f9fafc;
  height: 216px !important;
  margin-top: 25px;
}
.empty-content {
  max-width: 249px;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  text-align: center;
  h2 {
    font-family: 'SF_Medium', sans-serif;
    letter-spacing: 0.16px;
    color: #1f2d3d;
    opacity: 1;
    margin-bottom: 10px;
    font-size: 16px;
  }
  p {
    color: #8492a6;
    font-family: 'SF_medium', sans-serif;
    font-size: 13px;
  }
}
/*
* Off Canvas at medium breakpoint
* --------------------------------------------------
*/
.dash-content-wrapper {
  padding: 0 0 0 60px;
}
.dash-content-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}
.dash-filter-option {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.dash-filter-option > div:nth-child(2n) {
  border-right: 1px solid black;
  height: 24px;
  padding: 0 10px;
  margin: 0 17px;
}
.dash-filter-option > div:first-child {
  flex-grow: 0;
}
.dash-filter-option > div:last-child {
  flex-grow: 0;
}
.search-wrapper {
  input[type='text'] {
    width: 130px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    //background-image: url('../../../public/images/search.png');
    //background-position: 10px 10px;
    //background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    &:focus {
      width: 100%;
    }
  }
}
.dash-tab {
  height: 100vh;
  display: flex;
  width: calc(100% - 30px);
  flex-direction: column;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-content: flex-start;
  margin-bottom: 40px;
  position: absolute;
  top: 0px;
  padding-top: 210px;
  padding-left: 30px;
  padding-right: 30px;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav-tabs {
    border-bottom: none;
  }
  .nav-tabs .nav-link {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 5px 0 0 0;
    margin: 0 20px 0 0;
    color: #172b4d;
    font-size: 14px;
    font-family: 'SF_medium', sans-serif;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-color: #fff;
    border-bottom: 2px solid #1f2d3d;
    color: #172b4d;
  }
}
.upgrade-popup {
  .ant-modal-close {
    color: #515151;
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
    svg {
      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }
  .ant-modal-content {
    background: #FFFDF6;
    border-radius: 12px;
    border: 0;
  }
  .ant-modal-body {
    background: #FFFDF6;
    border-radius: 12px;
    border: 0;
  }
  .left-wrap {
    text-align: left;
    padding: 80px 80px;
    img {
      display: none;
    }
    h3 {
      font-family: "SF_semiBold", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #767676;
      margin-bottom: 25px;
    }
    ul {
      li {
        font-family: "SF_Regular", sans-serif;
        font-weight: normal;
        list-style: disc;
        font-size: 18px;
        line-height: 22px;
        color: #767676;
        margin-bottom: 10px;

      }
    }
  }
  .right-wrap {
    text-align: left;
    padding: 80px 80px;
    img {

    }
    h3 {
      font-size: 18px;
      line-height: 22px;
      color: #515151;
      margin-bottom: 25px;
      font-family: "SF_Regular", sans-serif;
    }
    button {
      border-radius: 6px;
      color: #fff;
      border: none;
      width: 294px;
      height: 71px;
      display: flex;align-items: center;justify-content: center;
      gap: 10px;
      margin-bottom: 50px;
      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }
      .icon-upgrade {
        color: #FFC502;
      }
      span {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
      }
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        color: #FFECAD;
      }
      a {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.dashboard-nav {
  font-family: "SF_Medium", sans-serif;
  height: 64px;
  padding: 10px 25px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  backdrop-filter: saturate(180%) blur(20px);
  background: #fff;
  z-index: 99;

  .nav-brand {
    max-width: 130px;
    width: 100%;
  }
}
.site-layout-background {
  padding: 0;
  height: 100vh;
  position: relative;
}
.dashboard-body {
  // height: calc(100vh - 49px);
  overflow-x: hidden;
  overflow-y: auto;
  .col-100,
  .col-100 .row,
  [class*='col-'],
  .dashboard-left-wrapper {
    height: 100%;
  }
  .dashboard-left-wrapper {
    border-right: 2px solid #f6f7f9;
    padding-right: 15px;
  }
  .left-panel,
  .right-panel {
    overflow: hidden;
    padding-top: 40px;
    height: 100%;
  }
  .right-panel {
    // max-width: 85%;
    //width: 100%;
    margin-left: 25px;
  }
  .right-conatiner-space {
    //max-width: 91%;
  }
  .templateListing {
    .temTable {
      //overflow-x: hidden;
      padding-bottom: 15px;
      height: calc(100vh - 250px);

      //overflow-y: auto;
      //-ms-overflow-style: none;  /* IE and Edge */
      //scrollbar-width: none;  /* Firefox */
      //&::-webkit-scrollbar { width: 0 !important }
      table {
        white-space: nowrap;
      }


      /* width */
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #ddd;transition: 0.5s ease-in-out;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
        transition: 1s ease-in-out;
      }
    }

  }
}

.ant-popover {
  .ant-popover-inner {
    border-radius: 6px;
    box-shadow: 0;

    .ant-popover-title {
      padding: 10px 18px;
    }
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}
.dashboardLink {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
}
.breadcrumbTitle {
  white-space: nowrap;
  &.textLimit {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 360px;
  }
  h1 {
    color: #172B4D;
    font-size: 24px;
    line-height: 32px;
    font-family: "SF_Bold", sans-serif;
    margin-bottom: 35px;
    text-align: left;
    span {
      font-family: "SF_Regular", sans-serif;
      color: #8493a6 !important;
      font-size: 24px !important;
      line-height: 32px;
      display: inline !important;
    }
  }
}
.upload-dashboard-skinning {
  max-width: 475px;
  padding: 10px;
  width: 100%;
  margin: 100px auto 15px auto;
  background: #fff;
  h1 {
    color: #172B4D;
    font-size: 24px;
    line-height: 32px;
    font-family: "SF_Bold", sans-serif;
    margin-bottom: 35px;
    text-align: center;
    span {
      font-family: "SF_Regular", sans-serif;
      color: #9E9E9E;
    }
  }
  .creatingHeading {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;

    img {
      margin-right: 15px;
      width: 32px;
    }
    h3 {
      margin-bottom: 0;
      color: #172B4D;
      font-weight: 600;
      font-size: 24px;
      letter-spacing: 1px;
      line-height: 26px;
      font-family: "SF_Medium", sans-serif;
    }
  }
  .recorder-hit {
    margin-bottom: 14px;
    .btn {
      width: 100%;
      height: 87px;
      img {
        margin-right: 20px;
      }
    }
  }
  .templates-hit {
    .btn {
      width: 100%;
      height: 58px;
      background: #F8FBFF;
      border: transparent !important;
      img {
        margin-right: 20px;
      }
    }
  }
  .dragger-container {
    border: 1px dashed #E0E0E0;
    border-radius: 6px;
    .ant-upload.ant-upload-drag {
      border: none;
      .ant-upload {
        padding: 0;
      }
    }
    .drag-dashboard {
      //background: url(" + "https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350" + ");
      background-size: cover;
      background-repeat: no-repeat;
      height: 440px;
      background-position: center;
      background-size: inherit;
    }
  }
}

.time-span {
  display: flex;
  align-items: center;
  gap: 80px;
  color: #D0D0D0;
  margin-bottom: 15px;
  button {
    cursor: pointer;
    border: 0;
    background: transparent;
    font-family: "SF_semiBold", sans-serif;
    outline: none !important;
    &.active {
      color: #565656;
    }
  }
}
.signature-counter-content {
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 7px 7px 7px 7px;
  display: flex;
  align-items: center;
  width: fit-content;
  .signature-box {
    // max-width: 335px;
    width: 100%;
    border-right:  1px solid #E2E2E2;
    display: flex;
    justify-content: space-between;
    padding: 2px 25px;
    align-items: center;
    p {
      font-family: "SF_semiBold", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #616161;
      margin: 0;
      white-space: nowrap;
      margin-right: 30px;
      span {
        color: #9E9E9E;
        white-space: nowrap;
      }
    }
    h3 {
      font-weight: 600;
      margin-bottom: 0;
      font-size: 36px;
      line-height: 44px;
      font-family: "SF_semiBold", sans-serif;
      color: #9E9E9E;
      text-align: right;
      margin-left: 5px;
    }
    &:last-child {
      border-right: none;
    }
  }
}




.widthMenu {
  width: 170px;
}
.profileDropdown {
  &.dropdown.noIcon {
    .dropdown-toggle::after {
      display: none;
    }
  }
  .dropdown-menu.dropdown-menu-right {
    left: initial;
    right: 0;
    top:3rem;
  }
  
  .user-avatar {
    width: 35px;
    height: 35px;
    // background: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    img.defaultAvatar{
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    &::before{
      content: "";
      background-image: url('./assets/icons/downChevron.png');
      background-position: center;
      background-size: contain;
      width:7px;
      height: 7px;
      position: absolute;
      left: auto;
      right: -15px;
      top: 40%;
    }
    .avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .dropdown-menu {
    border: 1px solid #F8F8F8;
    box-shadow: 0px 3px 14px #00000020;
    background: #fff;
    padding: 0;
    border-radius: 0;
    width: 250px;
    .dropdown-item {
      color: #8E8E8E;
      font-size: 14px;
      line-height: 26px;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 0;
      padding: 10px;
      text-align: center;
      border-bottom: 1px solid #ECECEC;
      transition: 0.5s ease-in-out;
      font-family: "SF_Regular", sans-serif;
      &:hover {
        color: #0080ff !important;
        background: transparent;
      }
    }
    .dropdown-item-quata {
      background-color: #f5f5f5;
      color: #8E8E8E;
      font-size: 14px;
      line-height: 26px;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 0;
      padding: 10px;
      border-bottom: 1px solid #ECECEC;
      transition: 0.5s ease-in-out;
      font-family: "SF_Regular", sans-serif;
    }
  }
}
.newRecordMenu {
  &.ant-dropdown-menu {
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.11));
    border: 1px solid #EBEBEB;
    border-radius: 0 0 6px 6px;
    padding: 0;
    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
      height: 51px;
      border-bottom: 1px solid #EBEBEB;
      text-align: center;
      &:last-child {
        border-bottom: none;
      }
    }
    .ant-dropdown-menu-title-content > a {
      color: #737373;
      font-size: 14px;
      line-height: 26px;
      font-family: "SF_Medium", sans-serif;
      transition: 0.5s ease-in-out;
      &:hover {
        color: #0080ff;
      }
    }
  }
}

.btn {
  font-family: "SF_medium", sans-serif;
  font-style: normal;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.vidprop-theme {
  .btnAlpha {
    color: #fff;
    background: #FF715B !important;
    border-color: #FF715B !important;
    font-family: "SF_semiBold", sans-serif;
    font-size: 14px;
    letter-spacing: 0.7px;
    border-radius: 4px;
    &.small {
      height: 31px;
      padding: 0px 5px;
      font-size: 12px;
    }
    &:hover {
      background: #fa5a41 !important;
      color: #fff !important;
    }
  }
  .btnBeta {
    font-family: "SF_medium", sans-serif;
      letter-spacing: 0px;
      color: #3C4858;
      opacity: 1;
      margin-bottom: 0;
      background: #fff 0% 0% no-repeat padding-box;
      border: 1px solid #E0E6ED ;
      border-radius: 4px;
      font-size: 14px;
      transition: 0.5s ease-in-out;
      height: 41px;
    &.small {
      height: 31px;
      padding: 0px 5px;
      font-size: 12px;
    }
    &:hover {
      border: 1px solid #f55138;
      color: #f55138;
    }
  }
}

.vumu-theme {
  .btnAlpha {
    color: #fff;
    background: #0080ff;
    border-color: #0080ff;
    font-family: "SF_semiBold", sans-serif;
    font-size: 14px;
    letter-spacing: 0.7px;
    border-radius: 4px;
    transition: all 150ms;
    &.small {
      height: 31px;
      padding: 0px 5px;
      font-size: 12px;
    }
    &:hover {
      background: #0080ff;
      border-color: #0080ff;
    }
  }
  .btnBeta {
      font-family: "SF_medium", sans-serif;
      letter-spacing: 0px;
      color: #3C4858;
      opacity: 1;
      margin-bottom: 0;
      background: #fff 0% 0% no-repeat padding-box;
      border: 1px solid #E0E6ED ;
      border-radius: 4px;
      font-size: 14px;
      transition: 0.5s ease-in-out;
      height: 41px;
      min-width: 90px;
      svg {
        transition: 0.5s ease-in-out;
        stroke: #C4C4C4;
        fill: #C4C4C4;
      }

      &.small {
        height: 31px;
        padding: 0px 5px;
        font-size: 12px;
      }
    &:hover {
      border: 1px solid #737373 ;
      background: #f4f6f9;
      color: #737373;
      
      svg {
        stroke: #737373;
        fill: #737373;
      }
    }
  }
}

.ant-btn {
  height: 40px;
}
.ant-btn.disabled,
.ant-btn:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: transparent !important;
  cursor: not-allowed;
  opacity: 0.4 !important;
}
.upload-banner-btn {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px 40px;
  background-color: #0080ff;
  transition: color 0.2s ease;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
}
.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: none !important;
}
.btn-dark {
  color: #fff;
  background-color: #3c4858;
  border-color: #3c4858;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  cursor: not-allowed;
}
.btn-outline-dark {
  color: #3C4858D9;
  background-color: transparent;
  background-image: none;
  border: 1px solid #3C4858D9;
}
.btn-outline-light {
  color: #a5acbb;
  background-color: transparent;
  background-image: none;
  border: 1px solid #E5E9F2;
   &:hover {
    border: 1px solid #3C4858D9;
    color: #3C4858D9;
   }
}
.btn-outline-primary {
  color: #fff;
  background-color: #0080ff;
  background-image: none;
  border: 1px solid #0080ff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0080ff;
  border-color: #0080ff;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0080ff;
  border-color: #0080ff;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-light {
  color: #1f2d3d;
  background-color: #fff;
  border-color: #1f2d3d;
}
.btn-light:hover {
  color: #1f2d3d;
  background-color: #fafbfc;
  border-color: #fafbfc;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #1f2d3d;
  background-color: #f9fafc;
  border-color: #f9fafc;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary {
  color: #fff !important;
  background-color: #0080ff !important;
  border-color: #0080ff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0080ff !important;
  border-color: #0080ff;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0080ff;
  border-color: #0080ff;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary-dark {
  color: #fff !important;
  background-color: #6f79be !important;
  border-color: #6f79be;
}
.btn-primary-dark:hover {
  color: #fff;
  background-color: #6f79be !important;
  border-color: #6f79be;
}
.btn-primary-dark:not(:disabled):not(.disabled).active,
.btn-primary-dark:not(:disabled):not(.disabled):active,
.show > .btn-primary-dark.dropdown-toggle {
  color: #fff;
  background-color: #6f79be;
  border-color: #6f79be;
}
.btn-primary-dark:not(:disabled):not(.disabled).active:focus,
.btn-primary-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary-dark.dropdown-toggle:focus {
  box-shadow: none;
}
.container-mini-lead {
  max-width: 888px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.dash-left-menu {
  li {
    a {
      display: flex;
      align-items: center;
      color: #172b4d;
      padding: 7px 10px;
      border-radius: 3px;
      font-size: 14px;
      font-family: 'SF_medium', sans-serif;
      span,
      i,
      img {
        font-size: 20px;
        margin-right: 7px;
      }
      &:hover,
      &:active,
      &:focus {
        background: #f6f7f9;
      }
      a.active {
        background: #f6f7f9;
      }
    }
  }
}

.dash-folder-wrap {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-content: flex-start;
  margin-bottom: 40px;
  &::-webkit-scrollbar {
    display: none;
  }
  .folderCreateBem{
    position: relative;
    cursor: pointer;
    width: 220px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px dashed #f4f6f9;
    // transition: all .250s ease-in-out;
    animation: fades-in 300ms linear;
    transition: all ease-in-out .5s;
    svg{
      width:50px;
      height: 100%;
      transition: all ease-in-out .5s;
    }
    .plusIcon{
      position: absolute;
      left: 0;
      right: 0;
      margin-top: -5px;
      display: flex;
      justify-content: center;
      align-items: center;
      color:#fff;
      font-weight:600;
      font-size: 18px;
      transition: all ease-in-out .5s;
    }
    .addFolderTitle{
      position: absolute;
      left: 0;
      right: 0;
      margin-top: 0rem;
      font-weight:600;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      color: #fff;
      height: 60%;
      font-size: 14px;
      transition: all ease-in-out .5s;
    }
  }
  .dash-folder-item {
    position: relative;
    cursor: pointer;
    // width: 194px;
    // height: 178px;
    width: 220px;
    height: 180px;
    border-radius: 10px;
    border: 1px solid #f4f6f9;
    // transition: all .250s ease-in-out;
    animation: fades-in 300ms linear;
    transition: all ease-in-out .5s;
    @keyframes fades-in {
      from {
        opacity: .5;
      }
      to {
        opacity: 1;
      }
    }

    
    // &:hover {
    //   box-shadow: 10px 10px 10px #0000000d;
    // }
    // &:first-child {
    //   //border: none;
    // }
    &.dir-folder {
      .imgWrapper {
        height:100%;
        justify-content: space-evenly;
        background: transparent;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 12px 18px;
        .dir-item-img {
          //width: 85px;
          //height:85px;
          //border-radius: 100%;
          //display: flex;
          //flex-direction: column;
          //align-items: center;
          //justify-content: center;
          //background: #fff;
          display: flex;
          gap: .8rem;
          img {
            width: 95px;
            object-fit: contain;
            margin-bottom: 0px;
            margin-top: 0px;
          }
          svg.svgIcon {
            width: 31px;
            margin-bottom: 21px;
            margin-top: 15px;
            stroke: #E2E2E2;
          }
          span {
            font-size: 10px;
            color: #8492A6;

          }
        }
        .dir-item-date {
          p{
            margin-bottom: 0;
            font-size: 12px;
            line-height: 26px;
            letter-spacing: 0px;
            font-family: "SF_Medium", sans-serif;
            color: #AFAFAF;
          }
        }
      }

      .dir-item-title {
        letter-spacing: 0px;
        color: #737373;
        font-family: 'SF_semiBold', sans-serif;
        line-height: 16px;
        width: 100%;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        //white-space: nowrap;
        text-align: left;
        min-height: 50px;
        max-height: 50px;
        margin-bottom: 5px;
      p{
        margin-bottom: 0;
        font-size: 10px;
        letter-spacing: 0px;
        color: #8492A6;
      }
      }
    }
    .ellipsis-outer {
      position: absolute;
      transform: rotate(90deg);
      right: 0px;
      top: 10px;
      background: transparent;
      width: 27px;
      height: 18px;
      border-radius: 5px 10px 5px 5px;
      font-size: 17px;
      color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        circle{
          transition: all ease-in-out .5s;
          fill: transparent;
        }
      }

    }
    .file-item-overlay {
      //display: none;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      // background: #f4f6f9;
      border-radius: 10px;
      backdrop-filter: saturate(180%) blur(20px);
      background: rgba(0, 0, 0, .60);

      animation: fades-in 100ms ease-in;

      .overlay-view-btn {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #F8F8F8;
        box-sizing: border-box;
        border-radius: 6px;
        width: 98px;
        height: 28px;
        color: #172B4D;
        font-size: 13px;
        font-family: "SF_semiBold", sans-serif;
        line-height: 22px;
        border: 1px solid transparent;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
        }
        span {
          margin-left: 10px;
        }
      }
      .info-top {
        position: absolute;
        right: 0px;
        top: 0px;
        backdrop-filter: saturate(180%) blur(20px);
        background: transparent;
        width: 27px;
        height: 18px;
        border-radius: 0px 5px 0px 5px;
        font-size: 17px;
        color: #989898;
        // box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .info-bottom {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .views {
          //line-height: 40px;
          //font-weight: bold;
          color: white;
          font-size: 13px;
          text-align: center;
          font-weight: bold;
          span {
            font-size: 12px;
            font-family: "SF_Medium", sans-serif;
            line-height: 22px;
            margin: 0 5px;
          }

          .overlay-actions {
            .btn-repurpose, .btn-link {
              display: inline-block;
              // background: #F8F8F8;
              background: rgba(251, 251, 253, 1);
              border: 1px solid transparent;
              border-radius: 6px;
              min-width: 120px;
              // width: 132px;
              color: black;
              padding: 8px 24px;
              font-weight: 500;
              font-size: 14px;
              text-decoration: none;
              margin: 6px;
              cursor: pointer;
              transition: all 100ms;
              font-weight: 600;
              letter-spacing: .7px;

              &:hover {
                border: 1px solid transparent;
                background: #8592a6;
                color: white;
              }
            }
            .btn-link {
              background: transparent;
              border: 1px solid #e0e0e0;

              img {
                vertical-align: middle;
                display: inline-block;
                margin-top: -2px;
              }
            }
          }
        }
      }
    }

    .dragIcon {
      position: absolute;
      left: 10px;
      top: 5px;
      cursor: pointer;
    }
    .itemMenu {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 20px;
      font-weight: bolder;
      transition: 0.2s ease-in-out;
      width: 22px;
      height: 22px;
      color: #172b4d;
      &:hover {
        //color: #1890ff;
        background: #3938564d;
        border-radius: 5px;
      }
    }

    .itemCheckbox {
      position: absolute;
      left: 10px;
      top: 10px;
      .ant-checkbox-inner {
        border: 1px solid #172b4d;
        width: 22px;
        height: 22px;
        border-radius: 5px;
      }

      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #1890ff;
      }

      .ant-checkbox-checked::after {
        border-radius: 5px;
      }
    }
    .upload-video-btn {
      background: #fafafaab 0% 0% no-repeat padding-box;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: 100%;
      border-radius: 20px;
      border: 2px dashed #E5E9F2;
      img {
        width: 85px;
        margin-bottom: 5px;
        margin-top: 20px;
      }
      label {
        font-size: 16px;
        line-height: 24px;
        font-family: 'SF_Bold', sans-serif;
        letter-spacing: 0.2px;
        color: #1f2d3d;
        margin: 0 0 20px;
      }
    }

    video.folder-item-img {
      width: calc(100% - 6px);
      object-fit: contain;
      background: #111;
    }

    .folder-item-img {
        height: 123px;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: center !important;
        margin: 3px;
        border-radius: 4px;
      .folder-item-img {
        position: absolute;
      }
        img {
          width: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    .folder-item-title {
      letter-spacing: 0px;
      color: #000;
      font-family: 'SF_semiBold', sans-serif;
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 0;
      width: 100%;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      display: inline-block;
      border-radius: 0 !important;
      background: none !important;
      padding: 5px 10px;
    }
    .publishTime {
      font-size: 10px;
      display: block;
      width: 100%;
      margin-top: -3px;
      // padding: 0 10px;
      font-family: 'SF_medium', sans-serif;
      color: #8492A6;
      line-height: 11px;
    }
  }

}
.dash-folder-wrap.projectsBem {
  .dash-folder-item:hover {
    border: 1px solid #d4d4d4 !important;
  }
}
.dash-folder-wrap.folderBem {
  .dash-folder-item:hover {
    box-shadow:0 2px 3px #eeeeee;
    border:1px solid #E2E2E2;
    transition: all ease-in-out .5s;
    .ellipsis-outer {
      
      svg{
        circle{
          fill: #BDBDBD;
          transition: all ease-in-out .5s;
        }
      }

    }
  }
  .folderCreateBem:hover{
    box-shadow:0 0px 0px #eeeeee;
    border:2px dashed #E2E2E2;
    transition: all ease-in-out .5s;
    .addFolderTitle{
      color:#8F8E8E;
      transition: all ease-in-out .5s;
    }
    .plusIcon{ 
      color:#8F8E8E;
      transition: all ease-in-out .5s;
    }
    svg{
      // path{
      //   transition: all ease-in-out .5s;
      //   fill: linear-gradient( #9198e5,#e6646400);
      // }
    }
  }
}

.keevi-tools-tab {
  .nav-tabs {
    margin-bottom: 70px;
    gap: 10px;
    border-bottom: 0;
    .nav-link {
      background: #f4f5f7 0 0 no-repeat padding-box;
      border: 1px solid #ffffff;
      opacity: 1;
      border-radius: 50px;
      letter-spacing: 0;
      color: #1f2d3d;
      font-family: 'SF_Regular', sans-serif;
      font-weight: 400;
      font-size: 18px;
    }
    .nav-link {
      display: block;
      padding: 5px 20px;
    }
    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover {
      border-color: #e9ecef #e9ecef #dee2e6;
    }
    .nav-item.show .nav-link,
    .nav-link.active {
      background: #3c4858 0% 0% no-repeat padding-box;
      border: 1px solid #3c4858;
      opacity: 1;
      color: #fff;
    }
  }
}
//Tools Card
.tab-item-wrap {
  display: flex;
  justify-content: flex-start;
  grid-gap: 18px;
  gap: 18px;
  flex-wrap: wrap;
}
.kv-tools-card {
  cursor: pointer;
  width: 200px;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #c0ccda;
  border-radius: 10px;
  opacity: 1;
  padding: 15px;
  .kv-tool-img {
    background: #f9fafc;
    border-radius: 10px;
    width: 100%;
    height: 115px;
    margin-bottom: 20px;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
  .kv-tool-title {
    p {
      letter-spacing: 0.16px;
      color: #3c4858;
      opacity: 1;
      font-family: 'SF_medium', sans-serif;
      font-size: 16px;
      margin-bottom: 0;
      line-height: 18px;
    }
  }
}

//Product CARD

.products-card-wrap {
  display: flex;
  justify-content: flex-start;
  grid-gap: 18px;
  gap: 18px;
  flex-wrap: wrap;
}
.product-card {
  cursor: pointer;
  width: 200px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 15px;
  &.bg-alpha {
    background: transparent linear-gradient(270deg, #32eedc 0%, #0091ff 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 20px 20px #0000000d;
    opacity: 1;
  }
  &.bg-beta {
    background: transparent linear-gradient(270deg, #eb9cff 0%, #6f79be 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 20px 20px #0000000d;
    opacity: 1;
  }
  &.bg-charlie {
    background: transparent linear-gradient(270deg, #ff9a9a 0%, #b479c3 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 20px 20px #0000000d;
    border-radius: 20px;
    opacity: 1;
  }
  &.bg-gama {
    background: transparent linear-gradient(270deg, #9ee1ff 0%, #429b93 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 20px 20px #0000000d;
    border-radius: 20px;
    opacity: 1;
  }
  .product-card-icon {
    margin-bottom: 24px;
    width: 83px;
    height: 83px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    img {
      object-fit: cover;
      width: 100%;
      max-width: 41px;
    }
    i {
    }
  }
  .product-card-title {
    p {
      letter-spacing: 0.16px;
      color: #fff;
      opacity: 1;
      font-family: 'SF_medium', sans-serif;
      font-size: 16px;
      margin-bottom: 0;
      line-height: 18px;
    }
  }
}

.section {
  &.mb-large {
    margin-bottom: 200px;
  }
  &.mb-medium {
    margin-bottom: 100px;
  }
  &.mb-mini {
    margin-bottom: 60px;
  }
  .section-title {
    margin-bottom: 38px;
    h1 {
      letter-spacing: 0px;
      color: #172b4d;
      opacity: 1;
      font-family: 'SF_semiBold', sans-serif;
      margin-bottom: 0;
      font-size: 36px;
    }
  }
  .section-body {
  }
}

.flip {
  position: relative;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 1s;
  width: 100%;
  display: block;
  margin: 0 auto;
  border-radius: 20px;
}

.flip .front {
  border-radius: 20px;
  padding: 15px 15px;
  backface-visibility: hidden;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flip .back {
  backface-visibility: hidden;
  left: 0;
  position: absolute;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  justify-content: center;
  height: 100%;
  label {
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-size: 18px;
    margin-bottom: 0;
    font-family: 'SF_medium', sans-serif;
  }
  p {
    letter-spacing: 0px;
    font-family: 'SF_Regular', sans-serif;
    color: #ffffff;
    opacity: 1;
    font-size: 14px;
    margin-bottom: 0;
  }
}

.flip:hover {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.lead-link {
  color: #8492A6;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
   .link-item {
    border-bottom: 1px solid #DFDFDF;
   cursor: pointer;
    display:flex;
    align-items: center;
    gap: 4px;
    a {
      line-height: 22px;
    }
  }
}

.cursor-pointer{
  cursor: pointer;
}

.crossIcon{
	display: flex;
	right: 0;
	z-index: 99;
	background: #c0ccda;
	color: #fff;
	border-radius: 20px;
	padding: 1px 6px;
	margin-right: 9px;
	font-size: smaller;
	transition: 0.4s ease-in-out;

}
.crossIcon:hover{
	transform: scale(1.3);
}


.contextMenu {
  position: absolute;
  background: white;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.32);
  transition: none;
  z-index: 2;
  padding: 12px 12px;
  min-width: 240px;
  border-radius: 6px;
  &--option {
    padding: 8px 50px 8px 10px;
    min-width: 160px;
    font-size: 14px;
    font-family: 'SF_Regular', sans-serif;
    cursor: pointer;
    transition: none;
    border-bottom: 1px solid #f4f6f9;
    color: #737373;
    letter-spacing: .7px;
    &:last-child {
      border: none;
    }
    a {
      color: #737373;
      padding: 0 4px;
      letter-spacing: .7px;
    }
    &:hover {
      background: #F3F6F9;
      border-radius: 6px;
      color: #0080ff;
    }

    &:active {
      a {
        color: #0080ff;
      }
    }

    &__disabled {
      color: #999999;
      pointer-events: none;
    }
  }

  &--separator {
    width: 100%;
    height: 1px;
    background: #cccccc;
    margin: 0 0 0 0;
  }
}

.dragIcon {
  position: absolute;
  left: 10px;
  top: 5px;
  cursor: pointer;
}

.drag-for-breadcrumb {
  position: absolute;
  top: 5px;
  transform: translate(-50%, -50%);
  left: 1%;
  background: #ddd;
  border-radius: 5px;
  padding: 2px 5px;
}

.extention-bar {
  background: #F2F7FF;
  border-radius: 1px;
  opacity: 1;
  color: #8492A6;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  .closeBtn {
    cursor: pointer;
    position: absolute;
    right: 10px;
  }
  label {
    letter-spacing: 0px;
    font-size: 13px;
    line-height: 16px;
    font-family: "SF_medium", sans-serif;
    color: #8492A6;
    opacity: 1;
    margin-bottom: 0;
    a {
      text-decoration: underline !important;
      color: #0080ff;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 48em) {
  .row-offcanvas {
    position: relative;
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
  }
  .row-offcanvas-left .sidebar-offcanvas {
    left: -33%;
  }
  .row-offcanvas-left.active {
    left: 33%;
    margin-left: -6px;
  }
  .sidebar-offcanvas {
    position: absolute;
    top: 0;
    width: 33%;
    height: 100%;
  }
}
/*
* Off Canvas wider at sm breakpoint
* --------------------------------------------------
*/
@media screen and (min-width: 1024.5px) {
  .ant-layout-content {
    padding: 0 0px;
  }
}

@media screen and (max-width: 34em) {
  .row-offcanvas-left .sidebar-offcanvas {
    left: -45%;
  }
  .row-offcanvas-left.active {
    left: 45%;
    margin-left: -6px;
  }
  .sidebar-offcanvas {
    width: 45%;
  }
}
@media screen and (max-width: 1024px) {
  .products-card-wrap,
  .tab-item-wrap {
    justify-content: center;
  }
  .section {
    &.mb-large {
      margin-bottom: 100px;
    }
    &.mb-medium {
      margin-bottom: 60px;
    }
    &.mb-mini {
      margin-bottom: 50px;
    }
    .section-title {
      margin-bottom: 25px;
      h1 {
        letter-spacing: 0px;
        color: #172b4d;
        opacity: 1;
        font-family: 'SF_semiBold', sans-serif;
        margin-bottom: 0;
        font-size: 36px;
      }
    }
    .section-body {
    }
  }
  .dashboard-body {
    //height: calc(100vh - 70px);
    .container-fluid,
    .row,
    [class*='col-'],
    .dashboard-left-wrapper {
      height: 100%;
    }
    .left-panel,
    .right-panel {
      padding-top: 40px;
      height: 100%;
    }
    .right-panel {
      max-width: 100%;
      width: 100%;
      margin-left: 0;
    }
  }
}
@media screen and (max-width: 768.5px) {
  .page-title {
    font-family: "SF_medium", sans-serif;
    font-size: 22px;
    color: #21252F;
  }

  .signature-counter-content {
    flex-wrap: wrap;
    .signature-box {
      // max-width: 300px;
      border-right: none;
      padding: 2px 10px;
      p {
        font-size: 13px;
        line-height: 16px;
        margin-right: 15px;
        span {
        }
      }
      h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        margin-left: 3px;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .upgrade-popup {
    .left-wrap {
      padding: 40px 40px;
    }
    .right-wrap {
      padding: 40px 40px;
    }
  }
  .extention-bar {
    label {
      font-size: 14px;
    }
  }
  .extention-bar .closeBtn {
    top: 3px;
  }
  .hideOnTab {
    display: none;
  }
  .headerBtn {
    button {
      padding: 5px 2px;
    }
  }
  .products-card-wrap,
  .tab-item-wrap {
    justify-content: center;
  }
  .section {
    &.mb-large {
      margin-bottom: 100px;
    }
    &.mb-medium {
      margin-bottom: 60px;
    }
    &.mb-mini {
      margin-bottom: 50px;
    }
    .section-title {
      margin-bottom: 25px;
      h1 {
        letter-spacing: 0px;
        color: #172b4d;
        opacity: 1;
        font-family: 'SF_semiBold', sans-serif;
        margin-bottom: 0;
        font-size: 36px;
      }
    }
    .section-body {
    }
  }
  .dashboard-body {
    //height: calc(100vh - 70px);
    .container-fluid,
    .row,
    [class*='col-'],
    .dashboard-left-wrapper {
      height: 100%;
    }
    .left-panel,
    .right-panel {
      padding-top: 40px;
      height: 100%;
    }
    .right-panel {
      max-width: 100%;
      width: 100%;
      margin-left: 0px;
    }
  }
  .dash-tab{
    padding-top: 180px;
    width: 100%;
    .dash-folder-wrap{
      align-items: center;
      justify-content: center;
    }
  }
}
@media screen and (max-width: 767.5px) {
  .navbar-brand {
    display: inherit;
  }
  .ant-layout-sider {
    // position: absolute;
    // left: 0;
    // top: 0;
    // bottom: 0;
    .collapseDot {
      // opacity: 1 !important;
      display: none !important;
    }
    .collapseDot.forWeb {
      display: none;
    }
  }
  .collapseDot.forMob {
    display: flex;
    position: absolute;
    right: -17px;
    top: 20px;
    background: #fff;
    color: #797979;
    border-radius: 4px;
    cursor: pointer;
    z-index: 999;
  }
  .ant-layout-sider-collapsed {
    .collapseDot.forMob {
      right: -17px;
      padding: 10px 2px;
      left: -2px;
      top: 14px;
    }
  }
  .video-trim-modal{
    width: 98% !important;
    .video-element {
      height: 40vw;
      video{
        max-width: 70vw !important;
        max-height:40vw !important;
      }
    }
  }
 
}
@media screen and (max-width: 525px) {
  .my-app-listing, .recomended-card-listing {
    justify-content: center !important;
  }
  .breadcrumbTitle {
    h1 {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 22px;
      span {
        font-size: 18px !important;
        line-height: 22px;
      }
    }
  }
  .row.vertical-divider > div[class^="col-"] {
    border: none;
  }
  .upgrade-popup {
    .left-wrap {
      padding: 20px 20px;
      img {
        display: block;
      }
    }
    .right-wrap {
      padding: 20px 20px;
      img {
        display: none;
      }
    }
  }


}
.dash-tab{
  footer{
    margin-top:20vw;
  }
}
@media screen and (max-width: 992px)  {
  .dash-tab{
    footer{
      margin-top:20vw;
      .col-12{
        height:60%;
        display: flex;
        justify-content: center;
      }
    }
  }

}
@media screen and (max-width: 1920px) and (min-width:1401px) {
  .dash-tab{
    footer{
      margin-top:30vw;
    }
  }
}
@media screen and (max-width: 1400px) and (min-width:992px) {
  .dash-tab{
    footer{
      margin-top:30vw;
    }
  }
}
footer{
  width: 100%;
  height: 49px;
  background-color: #fff;
  margin-bottom: .5rem;
  .row{
    align-items: center;
    display: flex;
    height: 100%;
  }
  .pagesLink{
    display: flex;
    align-items: center;
    ul{
      margin: 0;
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      li{
        max-width:538px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.5px;
        margin-left: 1rem;
        a{
          color: #a6a4a4;
          cursor: pointer;
        }
      }
    }
  }
  .copyRightContent{
    display: flex;
    justify-content: start;
    align-items: center;
    p{
      max-width:660px;
      width:100%;
      margin-bottom: 0;
      color: #a6a4a4;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 15.5px;
      a{
        color: #AA56EB;
        font-weight: 600;
        cursor: pointer;
        &:hover{
          color: #AA56EB;

        }
      }
    }
  }
  .companyLogo{
    display: flex;
    justify-content: start;
    align-items: center;
  }
}
@media screen and (max-width: 992px) {
  footer{
    height: auto;
    .companyLogo{
      justify-content: center;
    }
  }
}
@media screen and (max-width: 480px) {
  .wrapperBody{
    .rightArea{
      .viewport{
        width: 100% !important;
        min-width: 100% !important;
      } 
    } 
  }
}


.dashboard-body{
  &::-webkit-scrollbar {
    width: 0px;
    height: 8px;
  }
}
.dashboard_body_innerLayout{
  height: 100vh;
}

.workspace-menu-item{
  :hover{
    color: #0080ff !important;
  }
}

.credit-limit-banner {
  background: linear-gradient(135deg, #0080ff 0%, #0055aa 100%);
  box-shadow: 0 4px 15px rgba(0, 128, 255, 0.2);
  border-radius: 8px;
  margin: 20px;
  padding: 8px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;


  .banner-content {
    display: flex;
    align-items: center;
    gap: 16px;

    .warning-icon {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 50%;
      padding: 8px 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 20px;
      }
    }

    p {
      margin: 0;
      font-size: 15px;
      line-height: 1.5;
      font-weight: 500;
    }
  }

  .upgrade-button {
    background-color: #FFFFFF;
    color: #0080ff;
    padding: 10px 24px;
    border-radius: 6px;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
  }
}