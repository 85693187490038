body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

@font-face {
  font-family: 'SF_light';
  src: url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Light.woff") format("woff2"), url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Light.woff") format("woff"), url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Light.ttf") format("truetype"); }
@font-face {
  font-family: 'SF_Regular';
  src: url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Regular.woff") format("woff2"), url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Regular.woff") format("woff"), url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Regular.ttf") format("truetype"); }
@font-face {
  font-family: 'SF_medium';
  src: url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Medium.woff") format("woff2"), url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Medium.woff") format("woff"), url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Medium.ttf") format("truetype"); }
@font-face {
  font-family: 'SF_Bold';
  src: url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Bold.woff") format("woff2"), url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Bold.woff") format("woff"), url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Bold.ttf") format("truetype"); }
@font-face {
  font-family: 'SF_semiBold';
  src: url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Semibold.woff") format("woff2"), url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Semibold.woff") format("woff"), url("https://devmedia.keevi.io/assets/fonts/lead-font/SFUIDisplay-Semibold.ttf") format("truetype"); }


/*# sourceMappingURL=index.css.map */
